import React, { useEffect, useState, useRef, useCallback } from "react";
import "./Sidebar.css";
// import provinceData from '../data/provinceData';
import storeDetails from "../data/StoreDetails";
import logo from "../assets/images/Marker_Icon.png";

// const mainRestId = 173;
// const fetchUrl="https://api.iorders.online/customer/retrieve_restaurants";
// const orderOnlineLink="https://orders.iorders.online/";
// const mainRestId = 7;
// const fetchUrl="https://devapi.iorders.online/customer/retrieve_restaurants";
// const orderOnlineLink="https://demo.iorders.online/";
const mainRestId = 173;
const fetchUrl = "https://api.iorders.online/customer/retrieve_restaurants";

function Sidebar({ onProvinceChange, onCoordinatesChange, onStoresChange }) {
  // const handleProvinceSelect = (event) => {
  //   onProvinceChange(event.target.value);
  // };

  const [stores, setStores] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  // const [province, setProvince] = useState("");
  const autocomplete = useRef(null);
  const [filterActive, setFilterActive] = useState(false);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
  const [browserLocation, setBrowserLocation] = useState({ lat: 0, lng: 0 });
  const [showScrollTop, setShowScrollTop] = useState(false);
  const progressPath = useRef(null);
  const [showAllStores, setShowAllStores] = useState(false);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setFilterActive(true);
  };

  const handlePlaceSelect = useCallback(() => {
    if (!autocomplete.current) {
      console.error("Autocomplete is not initialized yet.");
      return;
    }
    let addressObject = autocomplete.current.getPlace();
    if (addressObject.geometry) {
      const lat = addressObject.geometry.location.lat();
      const lng = addressObject.geometry.location.lng();
      onCoordinatesChange({ lat, lng }); // Pass the coordinates up to the parent component
      setFilterActive(true);
      setUserLocation({ lat, lng });
    }
    if (addressObject && addressObject.address_components) {
      setAddress(addressObject.formatted_address);
      const addressComponents = addressObject.address_components;
      const locality = addressComponents.find((comp) =>
        comp.types.includes("locality")
      );
      // const administrativeArea = addressComponents.find(comp => comp.types.includes("administrative_area_level_1"));
      setCity(locality ? locality.long_name : "");

      // Update selectedCity state to reflect the new city from autocomplete
      setSelectedCity(locality ? locality.long_name : "");

      // const provinceCode = administrativeArea ? administrativeArea.short_name : "";
      // if (provinceData[provinceCode]) {
      //   setProvince(provinceCode);
      //   onProvinceChange(provinceCode); // Directly call the onProvinceChange function
      // } else {
      //   console.error("Extracted province code does not match any known provinces:", provinceCode);
      // }
    }
  }, [
    setAddress,
    setCity,
    setSelectedCity,
    onCoordinatesChange,
    setFilterActive,
    setUserLocation,
  ]); // Include setSelectedCity in the dependency array // Include all used state setters and callbacks here

  useEffect(() => {
    const google = window.google;
    if (!google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }

    autocomplete.current = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      { types: ["geocode"], componentRestrictions: { country: "CA" } }
    );
    autocomplete.current.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autocomplete.current.addListener("place_changed", handlePlaceSelect);

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete.current);
    };
  }, [handlePlaceSelect]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    return HaversineDistance(lat1, lon1, lat2, lon2).toFixed(2) + " KM";
    // console.log("Calling API");
    // const google = window.google;
    // if (!google) {
    //   console.error("Google Maps JavaScript API not loaded");
    //   return;
    // }
    // if (lat1 === 0 && lon1 === 0) {
    //   return Promise.resolve("Location not set");
    // }

    // const origin = new google.maps.LatLng(lat1, lon1);
    // const destination = new google.maps.LatLng(lat2, lon2);

    // return new Promise((resolve, reject) => {
    //   const service = new google.maps.DistanceMatrixService();
    //   service.getDistanceMatrix({
    //     origins: [origin],
    //     destinations: [destination],
    //     travelMode: 'DRIVING',
    //   }, (response, status) => {
    //     if (status === 'OK') {
    //       const result = response.rows[0].elements[0];
    //       if (result.status === 'OK') {
    //         // Remove any commas from the distance text
    //         const distanceWithoutComma = result.distance.text.replace(',', '');
    //         resolve(distanceWithoutComma);
    //       } else {
    //         reject('Distance calculation failed: ' + result.status);
    //       }
    //     } else {
    //       reject('Distance Matrix request failed: ' + status);
    //     }
    //   });
    // });
  }

  function HaversineDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setBrowserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          console.warn("Error Fetching Location", error);
        }
      );
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const url = fetchUrl;
      const bodyData = {
        main_restaurant_id: mainRestId,
        method: "get_all_restaurants_for_customer",
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        });
        const data = await response.json();
        let citySet = new Set();
        const currentDay = new Date()
          .toLocaleString("en-CA", { weekday: "long" })
          .toUpperCase();
        const currentTime = new Date().toTimeString().split(" ")[0];
        // const currentTime='19:54:22'
        // console.log(currentDay, currentTime);
        let extractedData = storeDetails.data.restaurants
          .filter((restaurant) => restaurant.profile.id !== mainRestId)
          .map((restaurant) => {
            const city = restaurant.profile.address.split(",")[1].trim(); // Assuming city is the second element after splitting by comma
            citySet.add(city);
            const todaySchedules = restaurant.profile.available_days.filter(
              (day) => day.day === currentDay
            );
            let todayTakeoutAvailable = false;
            let todayDeliveryAvailable = false;
            // Determine if there is a split in order modes across all days
            let takeoutDeliverySplit = restaurant.profile.available_days.some(
              (schedule) => schedule.order_mode === 0
            );
            todaySchedules.forEach((schedule) => {
              const isOpenNow =
                currentTime >= schedule.opening_time &&
                currentTime <= schedule.closing_time;
              if (isOpenNow) {
                // Calculate time until closing
                const closingTime = new Date();
                const [closingHours, closingMinutes] =
                  schedule.closing_time.split(":");
                closingTime.setHours(closingHours, closingMinutes, 0);
                const timeDiff = (closingTime - new Date()) / 60000; // Difference in minutes

                // Check if the store reopens within a minute after closing
                const reopeningTime = new Date(closingTime);
                reopeningTime.setMinutes(reopeningTime.getMinutes() + 1);
                const reopensSoon = restaurant.profile.available_days.some(
                  (day) => {
                    const [openingHours, openingMinutes] =
                      day.opening_time.split(":");
                    const openingTime = new Date();
                    openingTime.setHours(openingHours, openingMinutes, 0);
                    return openingTime.getTime() === reopeningTime.getTime();
                  }
                );

                if (timeDiff < 30 && !reopensSoon) {
                  restaurant.closingSoon = `Closing in ${Math.ceil(
                    timeDiff
                  )} minutes`;
                }
              }
              if (schedule.order_mode === 1 && isOpenNow) {
                todayTakeoutAvailable = true;
              }
              if (schedule.order_mode === 0 && isOpenNow) {
                todayDeliveryAvailable = true;
              }
            });

            // If there is no split, set both takeout and delivery to the same availability
            if (!takeoutDeliverySplit) {
              todayTakeoutAvailable = todayDeliveryAvailable =
                todayTakeoutAvailable || todayDeliveryAvailable;
            }

            const isClosed =
              restaurant.profile.close_restaurent ||
              (!todayTakeoutAvailable && !todayDeliveryAvailable);
            let nextOpeningTime = null;

            if (isClosed && !restaurant.profile.close_restaurent) {
              // Find the next day the restaurant is open
              const daysOfWeek = [
                "SUNDAY",
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
              ];
              const currentDayIndex = daysOfWeek.indexOf(currentDay);
              const sortedDays = restaurant.profile.available_days
                .map((day) => ({
                  ...day,
                  dayIndex: daysOfWeek.indexOf(day.day),
                }))
                .sort((a, b) => a.dayIndex - b.dayIndex);
              // Check if there's a later opening time today
              const laterToday = sortedDays
                .filter(
                  (day) =>
                    day.dayIndex === currentDayIndex &&
                    currentTime < day.opening_time
                )
                .sort((a, b) =>
                  a.opening_time.localeCompare(b.opening_time)
                )[0];

              if (laterToday) {
                nextOpeningTime = `Opening Today at ${laterToday.opening_time}`;
              } else {
                // Find the next opening day
                const nextDay =
                  sortedDays.find((day) => day.dayIndex > currentDayIndex) ||
                  sortedDays[0];
                nextOpeningTime = nextDay
                  ? `Reopens On ${daysOfWeek[nextDay.dayIndex]} at ${
                      nextDay.opening_time
                    }`
                  : "Not available";
              }
            }
            return {
              name: restaurant.profile.restaurant_name,
              address: restaurant.profile.address,
              status: isClosed ? "Closed" : "Open",
              reopen: nextOpeningTime,
              phone: restaurant.profile.phone,
              lat: restaurant.profile.lat,
              lng: restaurant.profile.lng,
              title: restaurant.profile.title,
              takeout: todayTakeoutAvailable && !restaurant.profile.disable_takeout_restaurent
                ? "Takeout Available"
                : "No Takeout",
              delivery: todayDeliveryAvailable && !restaurant.profile.disable_delivery_restaurent
                ? "Delivery Available"
                : "No Delivery",
              distance: null,
              closingSoon: restaurant.closingSoon || null,
              city: city,
              orderOnlineLink: restaurant.profile.order_online_link,
              haversineDistance: HaversineDistance(
                browserLocation.lat,
                browserLocation.lng,
                restaurant.profile.lat,
                restaurant.profile.lng
              ),
            };
          });
        if (filterActive) {
          browserLocation.lat = 0;
          browserLocation.lng = 0;
          let filtered_stores = extractedData.filter(
            (restaurant) => restaurant.city === selectedCity
          );
          if (filtered_stores.length === 0) {
            for (let store of extractedData) {
              let distance = await calculateDistance(
                userLocation.lat,
                userLocation.lng,
                store.lat,
                store.lng
              );
              if (parseFloat(distance) <= 15) {
                filtered_stores.push(store);
              }
            }
          }
          extractedData = filtered_stores;
        }

        if (userLocation.lat !== 0 || userLocation.lng !== 0) {
          browserLocation.lat = 0;
          browserLocation.lng = 0;
          for (let store of extractedData) {
            store.distance = await calculateDistance(
              userLocation.lat,
              userLocation.lng,
              store.lat,
              store.lng
            );
          }
          // Sort by distance if distance is calculated
          extractedData.sort((a, b) => {
            return a.distance && b.distance
              ? parseFloat(a.distance) - parseFloat(b.distance)
              : 0;
          });
        }
        if (browserLocation.lat !== 0 || browserLocation.lng !== 0) {
          extractedData = extractedData.filter((store) => {
            const distance = store.haversineDistance;
            store.distance = `${distance.toFixed(2)} KM`;
            return distance < 15;
          });
          extractedData.sort((a, b) => {
            return a.haversineDistance - b.haversineDistance;
          });
        }

        setCities([...citySet]);
        setStores(extractedData);
        onStoresChange(extractedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [city, filterActive, userLocation, selectedCity, browserLocation]);

  useEffect(() => {
    const path = progressPath.current;
    if (!path) return;

    const pathLength = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = `${pathLength} ${pathLength}`;
    path.style.strokeDashoffset = pathLength;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 10ms linear";

    const updateProgress = () => {
      const scroll = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const progress = pathLength - (scroll * pathLength) / height;
      path.style.strokeDashoffset = progress;

      setShowScrollTop(scroll > 50);
    };

    window.addEventListener("scroll", updateProgress);
    return () => window.removeEventListener("scroll", updateProgress);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="sidebar">
      <h3>
        Enter your address to find the store closest to you, or select a city to
        find stores.
      </h3>
      <input
        id="autocomplete"
        type="text"
        placeholder="Address"
        className="input-field"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        autoComplete="new-password"
      />
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          margin: "5px",
          textAlign: "center",
        }}
      >
        OR
      </h3>
      <select
        className="select-field"
        value={selectedCity}
        onChange={handleCityChange}
        autoComplete="off"
      >
        <option value="" disabled>
          Select a City
        </option>
        {cities.map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        ))}
      </select>

      <div className="store-list">
        {(userLocation.lat !== 0 && userLocation.lng !== 0) ||
        (browserLocation.lat !== 0 && browserLocation.lng !== 0) ||
        selectedCity ? (
          <>
            <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Nearby Stores
            </h2>
            {stores.length > 0 ? (
              stores.map((store, index) => (
                <div key={index} className="store-item">
                  <div className="store-header">
                    <img
                      src={logo}
                      alt="Store logo"
                      style={{ width: "120px", height: "45px" }}
                    />
                    <h3>
                      {store.name.replace("Prairie Donair", "")}
                      <br />
                    </h3>
                  </div>
                  <h3>
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#666666",
                        marginTop: "3px",
                        marginBottom: "0px",
                      }}
                    >
                      {store.address}
                    </span>
                  </h3>
                  <div>
                    <div
                      className="store-status"
                      style={{
                        color: store.status === "Open" ? "green" : "red",
                        fontSize: "16px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {store.status}
                      </span>

                      {store.status === "Open" && (
                        <div style={{ paddingBottom: "5px" }}>
                          <div style={{ color: "black", margin: "0px" }}>
                            {" "}
                            Takeout:
                            {store.takeout === "Takeout Available" ? (
                              <span
                                style={{
                                  color: "green",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 15"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                >
                                  <path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
                                </svg>
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "red",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 14"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                >
                                  <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                </svg>
                              </span>
                            )}
                            Delivery:
                            {store.delivery === "Delivery Available" ? (
                              <span
                                style={{
                                  color: "green",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 15"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                >
                                  <path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
                                </svg>
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "red",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 13"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                >
                                  <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                </svg>
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {store.status === "Closed" && store.reopen && (
                      <p className="store-status" style={{ color: "red" }}>
                        {store.reopen}
                      </p>
                    )}
                    {store.distance && (
                      <p style={{ color: "green", marginTop: "10px" }}>
                        {store.distance}s Away
                      </p>
                    )}
                    {store.closingSoon && store.status === "Open" && (
                      <p className="store-status" style={{ color: "orange" }}>
                        {store.closingSoon}
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <button
                      className="preorder-button"
                      style={{ flex: 1 }}
                      onClick={() =>
                        store.orderOnlineLink !== "N/A"
                          ? window.open(store.orderOnlineLink, "_blank")
                          : null
                      }
                      disabled={store.orderOnlineLink === "N/A"}
                    >
                      {store.orderOnlineLink === "N/A"
                        ? "Unavailable"
                        : store.status === "Open"
                        ? "Order Online"
                        : "Pre Order Online"}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-store">
                We are currently not available in this location.
              </p>
            )}
          </>
        ) : stores.length > 0 ? (
          <div>
            {!showAllStores && (
              <>
                <p>
                  No location selected. Would you like to see all available
                  stores?
                </p>
                <button
                  onClick={() => setShowAllStores(true)}
                  className="show-all-stores-button"
                >
                  Show All Stores
                </button>
              </>
            )}
            {showAllStores && (
              <>
                <h2 style={{ fontSize: "20px", fontWeight: "bold" }}>
                  All Stores
                </h2>
                {stores.map((store, index) => (
                  <div key={index} className="store-item">
                    <div className="store-header">
                      <img
                        src={logo}
                        alt="Store logo"
                        style={{ width: "100px", height: "45px" }}
                      />
                      <h3>
                        {store.name.replace("Prairie Donair", "")}
                        <br />
                      </h3>
                    </div>
                    <h3>
                      <span
                        style={{
                          fontSize: "13px",
                          color: "#666666",
                          marginTop: "3px",
                          marginBottom: "0px",
                        }}
                      >
                        {store.address}
                      </span>
                    </h3>
                    <div>
                      <div
                        className="store-status"
                        style={{
                          color: store.status === "Open" ? "green" : "red",
                          fontSize: "16px",
                        }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {store.status}
                        </span>

                        {store.status === "Open" && (
                          <div style={{ paddingBottom: "10px" }}>
                            <div style={{ color: "black", margin: "0px" }}>
                              {" "}
                              Takeout:
                              {store.takeout === "Takeout Available" ? (
                                <span
                                  style={{
                                    color: "green",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 15"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                  >
                                    <path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
                                  </svg>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 14"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                  >
                                    <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                  </svg>
                                </span>
                              )}
                              Delivery:
                              {store.delivery === "Delivery Available" ? (
                                <span
                                  style={{
                                    color: "green",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 15"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                  >
                                    <path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path>
                                  </svg>
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 13"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                  >
                                    <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
                                  </svg>
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      {store.status === "Closed" && store.reopen && (
                        <p className="store-status" style={{ color: "red" }}>
                          {store.reopen}
                        </p>
                      )}
                      {store.distance && (
                        <p style={{ color: "green", marginTop: "10px" }}>
                          {store.distance}s Away
                        </p>
                      )}
                      {store.closingSoon && store.status === "Open" && (
                        <p className="store-status" style={{ color: "orange" }}>
                          {store.closingSoon}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <button
                        className="preorder-button"
                        style={{ flex: 1 }}
                        onClick={() =>
                          store.orderOnlineLink !== "N/A"
                            ? window.open(store.orderOnlineLink, "_blank")
                            : null
                        }
                        disabled={store.orderOnlineLink === "N/A"}
                      >
                        {store.orderOnlineLink === "N/A"
                          ? "Unavailable"
                          : store.status === "Open"
                          ? "Order Online"
                          : "Pre Order Online"}
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <p className="no-store">
            We are currently not available in this location.
          </p>
        )}
      </div>
      <div
        className={`progress-wrap ${showScrollTop ? "active-progress" : ""}`}
        onClick={scrollToTop}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            ref={progressPath}
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          />
        </svg>
      </div>
    </div>
  );
}

export default Sidebar;

// useEffect(() => {
//   const fetchData = async () => {
//     const url = fetchUrl;
//     const bodyData = {
//         main_restaurant_id: mainRestId,
//         method: "get_all_restaurants_for_customer"
//     };

//     try {
//       const response = await fetch(url, {
//           method: 'POST',
//           headers: {
//               'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(bodyData)
//       });
//       const data = await response.json();
//       let citySet = new Set();
//       const currentDay = new Date().toLocaleString("en-CA", { weekday: 'long' }).toUpperCase();
//       const currentTime = new Date().toTimeString().split(' ')[0];
//       // const currentTime='19:54:22'
//       // console.log(currentDay, currentTime);
//       let extractedData = data.data.restaurants
//       .filter(restaurant => restaurant.profile.id !== mainRestId)
//       .map(restaurant => {
//         const city = restaurant.profile.address.split(",")[1].trim(); // Assuming city is the second element after splitting by comma
//         citySet.add(city);
//         const todaySchedules = restaurant.profile.available_days.filter(day => day.day === currentDay);
//         let todayTakeoutAvailable = false;
//         let todayDeliveryAvailable = false;
//         // Determine if there is a split in order modes across all days
//         let takeoutDeliverySplit = restaurant.profile.available_days.some(schedule => schedule.order_mode === 0);
//         todaySchedules.forEach(schedule => {
//           const isOpenNow = currentTime >= schedule.opening_time && currentTime <= schedule.closing_time;
//           if (isOpenNow) {
//             // Calculate time until closing
//             const closingTime = new Date();
//             const [closingHours, closingMinutes] = schedule.closing_time.split(':');
//             closingTime.setHours(closingHours, closingMinutes, 0);
//             const timeDiff = (closingTime - new Date()) / 60000; // Difference in minutes

//             // Check if the store reopens within a minute after closing
//             const reopeningTime = new Date(closingTime);
//             reopeningTime.setMinutes(reopeningTime.getMinutes() + 1);
//             const reopensSoon = restaurant.profile.available_days.some(day => {
//               const [openingHours, openingMinutes] = day.opening_time.split(':');
//               const openingTime = new Date();
//               openingTime.setHours(openingHours, openingMinutes, 0);
//               return openingTime.getTime() === reopeningTime.getTime();
//             });

//             if (timeDiff < 30 && !reopensSoon) {
//               restaurant.closingSoon = `Closing in ${Math.ceil(timeDiff)} minutes`;
//             }
//           }
//           if (schedule.order_mode === 1 && isOpenNow) {
//             todayTakeoutAvailable = true;
//           }
//           if (schedule.order_mode === 0 && isOpenNow) {
//             todayDeliveryAvailable = true;
//           }
//         });

//         // If there is no split, set both takeout and delivery to the same availability
//         if (!takeoutDeliverySplit) {
//           todayTakeoutAvailable = todayDeliveryAvailable = todayTakeoutAvailable || todayDeliveryAvailable;
//         }

//         const isClosed = restaurant.profile.close_restaurent || (!todayTakeoutAvailable && !todayDeliveryAvailable);
//         let nextOpeningTime = null;

//         if (isClosed && !restaurant.profile.close_restaurent) {
//           // Find the next day the restaurant is open
//           const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
//           const currentDayIndex = daysOfWeek.indexOf(currentDay);
//           const sortedDays = restaurant.profile.available_days
//             .map(day => ({
//               ...day,
//               dayIndex: daysOfWeek.indexOf(day.day)
//             }))
//             .sort((a, b) => a.dayIndex - b.dayIndex);
//           // Check if there's a later opening time today
//           const laterToday = sortedDays
//           .filter(day => day.dayIndex === currentDayIndex && currentTime < day.opening_time)
//           .sort((a, b) => a.opening_time.localeCompare(b.opening_time))[0];

//           if (laterToday) {
//             nextOpeningTime = `Opening Today at ${laterToday.opening_time}`;
//           } else {
//           // Find the next opening day
//           const nextDay = sortedDays.find(day => day.dayIndex > currentDayIndex) || sortedDays[0];
//           nextOpeningTime = nextDay ? `Reopens On ${daysOfWeek[nextDay.dayIndex]} at ${nextDay.opening_time}` : "Not available";
//           }
//         }
//         return {
//           name: restaurant.profile.restaurant_name,
//           address: restaurant.profile.address,
//           status: isClosed ? "Closed" : "Open",
//           reopen: nextOpeningTime,
//           phone: restaurant.profile.phone,
//           lat: restaurant.profile.lat,
//           lng: restaurant.profile.lng,
//           title: restaurant.profile.title,
//           takeout: todayTakeoutAvailable ? "Takeout Available" : "No Takeout",
//           delivery: todayDeliveryAvailable ? "Delivery Available" : "No Delivery",
//           distance: null,
//           closingSoon: restaurant.closingSoon || null,
//           city: city
//         };
//       });
//       if (filterActive) {
//         let filtered_stores = extractedData.filter(restaurant => restaurant.city === selectedCity);
//         if (filtered_stores.length === 0) {
//           for (let store of extractedData) {
//             let distance = await calculateDistance(userLocation.lat, userLocation.lng, store.lat, store.lng);
//             if (parseFloat(distance) <= 15) {
//               filtered_stores.push(store);
//             }
//           }
//         }
//           extractedData = filtered_stores;
//       }

//       if (userLocation.lat !== 0 || userLocation.lng !== 0) {
//         for (let store of extractedData) {
//           store.distance = await calculateDistance(userLocation.lat, userLocation.lng, store.lat, store.lng);
//         }
//         // Sort by distance if distance is calculated
//         extractedData.sort((a, b) => {
//           return a.distance && b.distance ? parseFloat(a.distance) - parseFloat(b.distance) : 0;
//         });
//       }
//       setCities([...citySet]);
//       setStores(extractedData);
//       onStoresChange(extractedData);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
//   };

// fetchData();
// },[city, filterActive, userLocation, selectedCity]);
